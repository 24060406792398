<template>
  <div id="mian">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>第三方名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="第三方名称/编号"
          ></el-input>
        </div>
        <div class="item">
          <i>启用状态</i>
          <el-select
            v-model="query.enabledStatus"
            placeholder="所有"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="已启用" :value="true"></el-option>
            <el-option label="未启用" :value="false"></el-option>
          </el-select>
        </div>
      </div>
      <div class="lineP">
        <div class="item itemTime">
          <i>创建时间</i>
          <el-date-picker
            v-model="query.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px">-</span>
          <el-date-picker
            v-model="query.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
        </div>
        <div class="itembtn">
          <el-button type="primary" class="searchBt" @click="getLists"
            >查询</el-button
          >
          <el-button
            v-auth="'ACCOUNT:WXAPP:THIRDS/ADD'"
            @click="addclickHander"
            class="outputBt"
            >新增</el-button
          >
        </div>
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>创建时间</p>
          </td>
          <td>
            <p>第三方名称</p>
          </td>
          <td>
            <p>第三方编号</p>
          </td>
          <td>
            <p>小程序数量</p>
          </td>
          <td>
            <p>启用状态</p>
          </td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td>
            <p>{{ v.createTime }}</p>
          </td>
          <td>
            <p>{{ v.thirdName }}</p>
          </td>
          <td>
            <p>{{ v.thirdNo }}</p>
          </td>
          <td>
            <p>{{ v.appNum }}</p>
          </td>
          <td>
            <p v-show="OFFON_ENABLESTATUS != 'ACCOUNT:WXAPP:THIRDS/ENABLESTATUS'"> {{ v.enabledStatus==true ? "已开启" : "已关闭" }}</p>
            <p v-auth="'ACCOUNT:WXAPP:THIRDS/ENABLESTATUS'">
              <el-switch
                v-model="v.enabledStatus"
                @change="setStatus(v)"
                active-color="#13ce66"
              >
              </el-switch>
            </p>
          </td>
          <td style="width: 66px">
            <p>
              <a
                v-auth="'ACCOUNT:WXAPP:THIRDS/DETAIL'"
                href="javascript:;"
                @click="showTab(v)"
                class="lianjie"
                >详情</a
              >
            </p>
          </td>
        </tr>
      </table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    >
    </el-pagination>
  </div>
</template>
<script>
import { thirdsList, setThirdsStatus } from "@/api/user/applet.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      query: {
        startTime: "",
        endTime: "",
        pageNo: 1,
        pageSize: 10,
        enabledStatus: "",
        keyword: "",
      },
      totalNum: 0,
      tabData: [],
      OFFON_ENABLESTATUS: 'ACCOUNT:WXAPP:THIRDS/ENABLESTATUS',
      preUrl:"",
    };
  },
  computed: {
    ...mapState({
      thirdsQueryDetail: "user_thirdsQuery",
    }),
  },
  created() {
    if (this.thirdsQueryDetail) {
      this.query = this.thirdsQueryDetail.query;
    }
    this.getLists(false);
    this.$enter(this.$route.path, this.getLists);
    this.listJurisdiction();
  },
  methods: {
    ...mapMutations({
      setThirdsQuery: "user_setThirdsQuery",
      setThirds: "user_setThirds",
    }),
    //新增按钮
    addclickHander() {
      this.setThirds(null);
      this.$router.push({
        name: "third_party_add",
      });
    },
    // 获取详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.query.startTime = this.query.startTime ? this.query.startTime : "";
      this.query.endTime = this.query.endTime ? this.query.endTime : "";
      this.query.keyword = this.query.keyword.trim();

      thirdsList(this.query).then((res) => {
        if (res) {
          this.tabData = res.resultData.thirds;
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    //启用、关闭机构
    setStatus(value) {
      this.$confirm("此操作将修改状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            thirdId: value.thirdId,
            enabledStatus: value.enabledStatus,
          };
          setThirdsStatus(data).then((res) => {
            if (!res) {
              value.enabledStatus = value.enabledStatus ? false : true;
            }
          });
        })
        .catch(() => {
          value.enabledStatus = value.enabledStatus ? false : true;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 查看
    showTab(v) {
      this.$router.push({
        name: "third_party_xp",
        query: {
          id:v.thirdId,
          pageType: "edit",
        },
      });
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
    //判断列表按钮是否有权限
    listJurisdiction(){
        const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
        if(_resources=='ALL'){
            this.OFFON_ENABLESTATUS = 'ACCOUNT:WXAPP:THIRDS/ENABLESTATUS';
        }else{
          if(_resources.split(';').indexOf('ACCOUNT:WXAPP:THIRDS/ENABLESTATUS') !== -1 ){
            this.OFFON_ENABLESTATUS = 'ACCOUNT:WXAPP:THIRDS/ENABLESTATUS';
          }else{
            this.OFFON_ENABLESTATUS = "";
          }
        }
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setThirdsQuery(data);
  },
};
</script>
<style scoped>
.con_from .lineP .item i {
  width: 74px;
}
.con_from .lineP .itemTime {
    width: 48.283%;
}
.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 74px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 74px - 24.67px - 10px) / 2);
}
</style>
